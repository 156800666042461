.container {
    padding: 100px 24px;
    background-image: linear-gradient(
        133deg,
        #112a4e 0%,
        #002d43 51%,
        #031b53 100%
    );
  box-shadow: rgb(0 0 0 / 60%) -8px 20px 14px -17px inset;
}

.inner {
    max-width: var(--container-width);
    margin: 0 auto;
    width: 100%;
    display: grid;
    grid-template-columns: auto auto auto auto;
    grid-gap: 24px;
    @media (max-width: 480px) {
        grid-template-columns: auto;
        justify-content: center;
        text-align: center;
        grid-gap: 48px;
    }
}


.block {
    
}

.logo {
    display: block;
    max-width: 178px;
    width: 100%;
    height: 74px;
    img {
        object-fit: contain;
        width: 100%;
        height: 100%;
    }
}

.heading {
    margin: 0;
    margin-bottom: 24px;
    font-size: 24px;
    font-family: var(--title-font-family);
    line-height: 1;
    letter-spacing: 0.01em;
    color: #fff;
}

.link {
    display: block;
    font-size: 16px;
    font-family: var(--title-font-text);
    line-height: 1;
    letter-spacing: 0.01em;
    color: #a199a9;
    &:hover {
        text-decoration: underline;
        color: #fff;
    }
}

.link + .link {
    margin-top: 12px;
}