.wallet-adapter-modal-logo-wrapper {
    background: transparent !important;
}

.wallet-adapter-modal-logo {
    padding: 8px;
}

.wallet-adapter-button {
    height: 46px!important;
    appearance: none!important;
    // background: #381BAD!important;
    background: linear-gradient(135deg, #4fadbbde 0%, #8166F3de 100%)!important;
    border: 1px solid #1D063F!important;
    color: #efecf9!important;
    font-size: 12px!important;
    padding: 0 20px!important;
    line-height: 0!important;
    white-space: nowrap!important;
    font-family: var(--mono-font-family)!important;
    cursor: pointer!important;
    border-radius: 4px!important;
    transition: all 0.255s ease-in-out;
    
    &:hover {
        
        // opacity: 1;
        // background: #381BAD!important;
        // background: linear-gradient(135deg, #4fadbb 0%, #8166F3 100%)!important;
        // background: linear-gradient(135deg, #4facbb 0%, #8166F3 100%)!important;
        border: 1px solid #50a2ff!important;
    }
   
    &:active {
        // background: #381BAD!important;
        // background: linear-gradient(135deg, #301694 0%, #6d56d4 100%)!important;
    }
}

.wallet-adapter-modal-title {
    color: #fff!important;
    font-family: var(--title-font-family);
    letter-spacing: 0.1em;
}

.wallet-adapter-modal-overlay {
    background: rgba(0, 0, 0, 0.1)!important;
}

.wallet-adapter-modal-wrapper {
    border: 1px solid #441150!important;
    background: rgb(55,12,59)!important;
    background: linear-gradient(138deg, #008CAE 0%, #0300AA 51%, #6A00B4 100%) !important;
}

.wallet-adapter-modal-button-close svg {
    fill: #5343b3!important;
    &:hover {
        fill: #fff!important;
    }
}

.wallet-adapter-modal-wrapper .wallet-adapter-button {
    height: 42px!important;
}

.wallet-adapter-modal-collapse-button svg {
    fill: #fff!important;
}