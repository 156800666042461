:root {
    --color-text-primary: #e4deff;
    --color-text-primary-inverted: #141416;

    --primary-font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
    --mono-font-family: "Roboto Mono", "SF Mono", "Monaco", "Inconsolata", "Fira Mono", "Droid Sans Mono", "Source Code Pro", monospace;
    --nunito-font-family: "Nunito Sans";
    --title-font-family: "Bebas Neue", var(--primary-font-family);

    --solice-card-gradient: linear-gradient(135deg, #0b2a2f 0%, #003d47 100%);
    --solice-card-border: 2px solid #0b2a2f;
    --solice-card-box-shadow: 0 2px 4px rgb(6, 1, 26);
    --solice-card-border-highlight: 2px solid #003d47;
    
    --container-width: 1270px;

    --container-max-width: 1640px;

    @media (max-width: 1440px) {
        --container-width: 940px;
    }

    @media (max-width: 940px) {
        --container-width: 680px;
    }

    @media (max-width: 680px) {
        --container-width: 480px;
    }

    @media (max-width: 480px) {
        --container-width: 320px;
    }
}