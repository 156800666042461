.container {
    position: relative;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto 1fr auto;
    overflow: hidden;
    max-width: 100vw;
    max-height: 100%;
}

.mainContent {
    padding: 48px 24px;
    z-index: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.background {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgb(55,12,59);
    background: linear-gradient(135deg, #0b2a2f 0%, #003d47 100%);
    z-index: -1;
    will-change: transform;
  }

  @keyframes diagonal {
    0% {
      opacity: 0.3;
      transform: translatex(-220px) translatey(-220px);
    }
    90% {
      opacity: 0.5;
    }
    100% {
      opacity: 0;
      transform: translatex(1100px) translatey(1100px);
    }
  }

.background1 {
    position: absolute;
    height: 0;
    top: -70px;
    right: 160px;
    pointer-events: none;
}

.background2 {
    position: absolute;
    height: 0;
    top: 640px;
    left: 60px;
    pointer-events: none;
}

.animatingBackground {
    opacity: 0;
    animation-name: diagonal;
    animation-iteration-count: infinite;
    position: absolute;
  }

.animatingBackground1 {
    width: 60px;
    left: 40vw;
    animation-duration: 40s;
    animation-delay: 0;
}

.animatingBackground2 {
    left: 70vw;
    animation-duration: 20s;
    animation-delay: 2s;
}

.animatingBackground3{
    left: -20vw;
    animation-duration: 43s;
    animation-delay: 4s;
}

.animatingBackground4 {
    animation-duration: 30s;
    margin-left: 15vw;
    animation-delay: 6s;
}

.animatingBackground5 {
    width: 80px;
    left: 5vw;
    animation-duration: 35s;
    animation-delay: 10s;
}

.animatingBackground6 {
    width: 80px;
    left: -40vw;
    animation-duration: 35s;
    animation-delay: 3s;
}

.animatingBackground7 {
    width: 180px;
    left: 75vw;
    animation-duration: 35s;
    animation-delay: 3s;
}

.animatingBackground8 {
    left: 55vw;
    animation-duration: 35s;
    animation-delay: 8s;
}