.container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: var(--container-max-width);
    width: 100%;
    

    &.isFloating {
        margin: 0 auto;
        margin-top: 24px;
        width: calc(100vw - 48px);
        background: rgba(0, 0, 0, 0.5);
        padding: 14px 24px;
        border-radius: 12px;
        @media (max-width: 680px) {
            flex-direction: column;
            width: 100%;
        }
    }


    @media (max-width: 680px) {
        flex-direction: column;
        width: 100%;
    }
}

.logo {
    max-width: 130px;
    width: 100%;
    height: 74px;
    img {
        object-fit: contain;
        width: 100%;
        height: 100%;
    }
}

.nav {
    display: flex;
    grid-gap: 48px;
    flex: 1;
    justify-content: flex-end;
    align-items: center;
    
    @media (max-width: 680px) {
        margin-top: 24px;
        flex-wrap: wrap;
        justify-content: flex-start;
        row-gap: 14px;
        justify-content: center;
    }
}

.navLink {
    position: relative;
    font-size: 20px;
    font-family: var(--title-font-family);
    line-height: 1;
    letter-spacing: 0.01em;
    color: #fff;
    
    &:hover:not(.popover) {
        text-decoration: underline;
    }
    &.popover {
        cursor: default;
    }

    &.popover span {
        position: absolute;
        border-radius: 8px;
        line-height: 1;;
        left: 50%;
        top: calc(100% + 8px);
        transform: translateX(-50%);
        width: fit-content;
        padding: 10px 12px 8px 12px;
        background: rgba(0,0,0,0.7);
        color: #fff;
        font-size: 16px;
        letter-spacing: 0.01em;
        text-transform: lowercase;
        white-space: nowrap;
        
        opacity: 0;
        pointer-events: none;
        transition: all 0.225s ease-in-out;
        
        &:after {
            bottom: 100%;
            left: 50%;
            border: solid transparent;
            content: "";
            height: 0;
            width: 0;
            position: absolute;
            pointer-events: none;
        }
        
        &:after {
            border-color: rgba(0, 0, 0, 0);
            border-bottom-color: rgba(0,0,0,0.7);
            border-width: 4px;
            margin-left: -4px;
        }
    }

    &:hover {
        &.popover span {
            opacity: 1;
        }   
    }   
}