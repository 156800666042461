/* roboto-mono-regular - latin */
@font-face {
    font-family: 'Roboto Mono';
    font-style: normal;
    font-weight: 400;
    src: local(''),
         url('./fonts/roboto-mono-v13-latin-regular.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
         url('./fonts/roboto-mono-v13-latin-regular.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  }
  /* roboto-mono-600 - latin */
  @font-face {
    font-family: 'Roboto Mono';
    font-style: normal;
    font-weight: 600;
    src: local(''),
         url('./fonts/roboto-mono-v13-latin-600.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
         url('./fonts/roboto-mono-v13-latin-600.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  }

  /* bebas-neue-regular - latin */
/* bebas-neue-regular - latin */
@font-face {
    font-family: 'Bebas Neue';
    font-style: normal;
    font-weight: 400;
    src: local(''),
         url('./fonts/bebas-neue-v2-latin-regular.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
         url('./fonts/bebas-neue-v2-latin-regular.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  }

  @font-face {
     font-family: 'Nunito Sans';
     font-style: normal;
     font-weight: 200;
     src: local(''),
          url('./fonts/nunito-sans-v8-latin-200.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
          url('./fonts/nunito-sans-v8-latin-200.woff') format('woff'), /* Chrome 26+, Opera 23+, Firefox 39+ */
   }