@import "./variables.scss";
@import "./wallet.scss";
@import "./nprogress.scss";
@import "./fonts.scss";

html,
body {
  padding: 0;
  margin: 0;
  line-height: 1.5;
  font-family: var(--primary-font-family);
  color: var(--color-text-primary);
}

html {
  background: #180640;
}


body {
  min-height: 100vh;
}

img {
  max-width: 100%;
}

#__next {
  display: grid;
  min-height: 100vh;
}

a {
  color: inherit;
  text-decoration: none;
}

p a {
  text-decoration: underline;
  &:hover {
    text-decoration: none;
  }
}

* {
  box-sizing: border-box;
}

.ReactModal__Body--open {
  overflow: hidden;
}